import { LPOverridable } from '@launchpad/components';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BreadcrumbHelper from '../../../util/BreadcrumbHelper';

export default class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crumbs: BreadcrumbHelper.crumbs
    };

    BreadcrumbHelper.onUpdate(crumbs => {
      this.setState({
        crumbs
      });
    });
  }

  render() {
    const elements = [];
    elements.push(
      <LPOverridable name="breadcrumb-home-item">
        <Link key="linkHome" to="/" className="breadcrumb-item">
          Home
        </Link>
      </LPOverridable>
    );

    for (let i = 0; i < this.state.crumbs.length; i++) {
      const crumb = this.state.crumbs[i];

      if (crumb.link) {
        elements.push(
          <Link key={`link${i}`} to={crumb.link} className="breadcrumb-item">
            {crumb.label}
          </Link>
        );
      } else {
        elements.push(
          <span key={`link${i}`} className="breadcrumb-item active">
            {crumb.label}
          </span>
        );
      }
    }

    return (
      <nav
        className="breadcrumb"
        style={{ height: 10, background: 'transparent' }}
      >
        {elements}
      </nav>
    );
  }
}
