import { all, takeEvery, fork, put } from 'redux-saga/effects';
import {
  MEMBER_LOGIN_TRIGGER,
  MEMBER_LOGOUT_TRIGGER,
  MEMBER_AUTH_REFRESH,
  MEMBER_TOKEN_LOADED,
  MEMBER_AUTH_LOADED
} from './AuthActions';
import { SHOULD_SHOW_IS_LANDING } from '../../logic/store/AppActions';
import Api from '../../logic/api/Api';
import OverrideService from '@launchpad/logic/services/overrides/OverrideService';

/**
 * Logout user
 *
 * @param {Object} action
 */
function* loginMember(action) {
  try {
    const accessToken = action.payload.token;
    // Set token in Api class (in-memory)
    Api.setToken(accessToken);
    const tokenToStore =
      OverrideService.getConfig().authStrategy === 'access-token'
        ? accessToken
        : '*********';
    window.localStorage.setItem('AUTH_TOKEN', tokenToStore);

    if (
      action.payload.token &&
      OverrideService.getOverrides()['get-user-info']
    ) {
      let result = null;

      if (OverrideService.getOverrides()['get-user-info']) {
        result = yield Api.call(
          OverrideService.getOverrides()['get-user-info'],
          'GET'
        );
      } else {
        result = yield Api.call('member/me');
      }

      if (result.success) {
        yield put({ type: MEMBER_AUTH_LOADED, payload: result.data });
      }
    } else {
      yield put({ type: MEMBER_AUTH_LOADED, payload: action.payload.account });
    }
  } catch (e) {}
}

function logoutMember(action) {
  try {
    window.localStorage.removeItem('AUTH_TOKEN', action.payload.token);
  } catch (e) {}
}

function* refreshAuth(action) {
  try {
    const token = window.localStorage.getItem('AUTH_TOKEN');

    if (token) {
      yield put({ type: MEMBER_TOKEN_LOADED, payload: token });

      let result = null;

      if (OverrideService.getOverrides()['get-user-info']) {
        result = yield Api.call(
          OverrideService.getOverrides()['get-user-info'],
          'GET'
        );
      } else {
        result = yield Api.call('member/me');
      }

      if (result.success) {
        yield put({ type: MEMBER_AUTH_LOADED, payload: result.data });
      }
    }
    yield put({ type: SHOULD_SHOW_IS_LANDING, payload: false });
  } catch (e) {}
}

export function* watchMemberLogin() {
  yield takeEvery(MEMBER_LOGIN_TRIGGER, loginMember);
}
export function* watchMemberLogout() {
  yield takeEvery(MEMBER_LOGOUT_TRIGGER, logoutMember);
}
export function* watchRefreshAuth() {
  yield takeEvery(MEMBER_AUTH_REFRESH, refreshAuth);
}

export default function* authSagas() {
  yield all([
    fork(watchMemberLogin),
    fork(watchMemberLogout),
    fork(watchRefreshAuth)
  ]);
}
