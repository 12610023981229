import React from 'react';
import Pagination from 'react-js-pagination';

export default class AppTablePagination extends React.Component {
  render() {
    if (!this.props.paginate) return null;

    if (!this.props.pagination || this.props.pagination.totalPages === 0)
      return null;

    const pages = this.props.pagination;

    if (pages.totalPages === 1) return null;

    let total_items = pages.total_items || pages.totalItems;

    return (
      <div className="pull-right">
        <span>Total items: {total_items}</span>
        <Pagination
          hideFirstLastPages
          innerClass="pagination pull-right"
          itemClass="page-item"
          itemClassFirst="page-item"
          itemClassNext="page-item"
          itemClassPrev="page-item"
          linkClass="page-link"
          linkClassFirst="page-link"
          activePage={this.props.pagination.page}
          itemsCountPerPage={pages.limit}
          totalItemsCount={total_items}
          pageRangeDisplayed={10}
          onChange={pageNumber => this.props.onPageChanged(pageNumber)}
        />
      </div>
    );
  }
}

AppTablePagination.defaultProps = {
  onPageChanged: page => console.log(`changing page to ${page}`),
  paginate: true
};
