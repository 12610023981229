/* eslint-disable max-classes-per-file */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

/**
 * Map state to props
 *
 * @param {Object} state
 */
let mapStateToProps = state => {
  return {
    token: state.auth.token,
    profile: state.auth.profile
  };
};

/**
 * Map dispatch to props
 *
 * @param {function} dispatch
 */
const mapDispatchToProps = dispatch => {
  return {
    redirect: token => dispatch(push(token))
  };
};

export class AppRoute extends React.Component {
  state = {
    component: null
  };

  render() {
    // Prevent authenticated to access public routes
    if (this.props.unAuthenticatedOnly && this.props.profile) {
      return <Redirect to="/" />;
    }

    // Prevent nonauthenticated to access private routes
    if (this.props.authenticatedOnly && !this.props.profile) {
      return <Redirect to="/login" />;
    }

    let { component: Component, layout: Layout, ...rest } = this.props;

    if (this.props.load) {
      Component = () => <DynamicLoad load={this.props.load} />;
    }

    if (Layout) {
      return (
        <Route
          {...rest}
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      );
    } else {
      return <Route {...rest} render={props => <Component {...props} />} />;
    }
  }
}

class DynamicLoad extends React.Component {
  state = {
    component: null
  };

  componentDidMount() {
    if (this.props.load && !this.state.component) {
      this.props.load().then(component => {
        this.setState({
          component: component.default ? component.default : component
        });
      });
    }
  }

  render() {
    if (this.state.component) {
      const Component = this.state.component;
      return <Component />;
    }
    return <div>Loading</div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
