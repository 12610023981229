import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import UsersNavigation from './UsersNavigation';
import Users from './Users';
import BulkLoadDashboard from './bulk-load/BulkLoadDashboard';

export const NAME = 'users';

export default class UsersModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'users.*';
  }

  getNavigation() {
    return [
      {
        label: 'Users',
        icon: 'users',
        path: null,
        permission: 'users.*',
        children: [
          {
            label: 'Users',
            icon: 'users',
            path: '/',
            permission: 'users.*'
          },
          {
            label: 'Bulk Load',
            icon: 'plus',
            path: '/users/bulk-load',
            permission: 'tools.bulkLoad.*'
          }
        ]
      }
    ];
  }

  getRoutes() {
    return [
      <AppRoute exact path="/" component={Users} />,
      <AppRoute path="/user-details" component={UsersNavigation} />,
      <AppRoute path="/users/bulk-load" component={BulkLoadDashboard} />
    ];
  }
}
