import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from '@launchpad/logic/api/Api';
import AccountBasicInfo from './tabs/AccountBasicInfo';
import AccountUserDetails from './tabs/AccountUserDetails';
import BreadcrumbHelper from '../../../../../@launchpad/util/BreadcrumbHelper';
import AppTabs from '../../../../../@launchpad/components/crud/tabs/AppTabs';
import Config from '../../../../../@launchpad/config';

export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      account: {},
      activeTab: 'basic'
    };
  }

  componentDidMount() {
    this._getAllAccounts();
  }

  getTabs(account) {
    const basicInfo = (
      <AccountBasicInfo fetcher={this._fetcher} account={account} />
    );
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo
      },
      {
        id: 'user',
        icon: 'user',
        title: 'User details',
        component: (
          <AccountUserDetails
            userId={account.userUuid}
            ownerId={account.ownerId}
          />
        )
      }
    ];
    return allTabs;
  }

  getAccount() {
    const { id } = this.props.match.params;

    const { items } = this.state;

    const account = items.find(x => x.id === id);

    this.setState(
      {
        account
      },
      () => {
        BreadcrumbHelper.setCrumbs([
          {
            label: 'Accounts',
            link: '/accounts'
          }
        ]);
        BreadcrumbHelper.addCrumb(this.state.account.name);
      }
    );
  }

  async _getAllAccounts() {
    const url = new URL(`${Config.url}admin/accounts`);
    url.search = new URLSearchParams({ page: '1', limit: '', value: '' });

    const getUrl = `admin/accounts${url.search}`;

    try {
      const result = await Api.call(getUrl, 'GET');

      this.setState(
        {
          items: result.data ? result.data : result
        },
        () => {
          this.getAccount();
        }
      );
    } catch (e) {}
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  _renderContent(accountDetails) {
    return (
      <div>
        <h1>
          <i className="fa fa-info" aria-hidden="true" /> Account Info:{' '}
          {accountDetails.name}
        </h1>
        <AppTabs
          onTabChange={activeTab => this.setState({ activeTab })}
          activeTab={this.state.activeTab}
          tabs={this.getTabs(accountDetails)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-management">
        {this._renderContent(this.state.account)}
      </div>
    );
  }
}

// Inject router
UserDetails.contextTypes = {
  router: PropTypes.object
};
