import React from 'react';

export const variants = {
  accessbank: {
    logo: require('../../assets/img/access_bank_logo.png'),
    cardFront: require('../../assets/img/access-bank-card-front.png'),
    loginText: () => (
      <h1>
        Welcome to Access bank Prepaid card{' '}
        <span style={{ display: 'block', textAlign: 'center' }}>
          Administration
        </span>
      </h1>
    )
  }
};

const currentVariant = process.env.REACT_APP_ADMIN_VARIANT || 'accessbank';
let exportableVariant = variants[currentVariant];
if (!variants[currentVariant]) {
  exportableVariant = variants.accessbank;
}
export const CurrentVariant = exportableVariant;
