import React from 'react';
import { withRouter } from 'react-router';
import qs from 'qs';
import _ from 'lodash';
import LoadingOverlay from '../LoadingOverlay';
import AppTableFilter from './AppTableFilter';
import AppTableHead from './AppTableHead';
import AppTablePagination from './AppTablePagination';
import { AppIcon } from '../../../index';

class AppTable extends React.Component {
  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      items: [],
      filter: this._getInitialFilter(props),
      order: this._getInitialOrder(props),
      pagination: {
        page: this._getInitialPage(props),
        total_pages: 0,
        limit: 20,
        total_items: 0
      },
      loadedTimes: 0
    };

    props.onInit(this);
  }

  componentDidMount() {
    this._loadItems();
  }

  getFilter() {
    return this.state.filter;
  }

  _getInitialFilter(props) {
    if (props.location.query.filter) {
      return this._joinFilters(props.filter, props.location.query.filter);
    }

    return props.filter;
  }

  _joinFilters(filter, otherFilters) {
    const mainFilter = [...filter];

    otherFilters.forEach(otherFilter => {
      const existingFilter = mainFilter.find(x => x.name == otherFilter.name);
      if (existingFilter) {
        const indexOf = mainFilter.indexOf(existingFilter);
        mainFilter[indexOf] = otherFilter;
        // mainFilter[indexOf] = {...existingFilter, ...otherFilter};
      } else {
        mainFilter.push(otherFilter);
      }
    });
    return mainFilter;
  }

  _getInitialOrder(props) {
    if (props.location.query.order) {
      return props.location.query.order;
    }
    return props.order || [];
  }

  /**
   * Loading off
   */
  _loadingOn() {
    this.setState({
      loading: true
    });
  }

  /**
   * Loading on
   */
  _loadingOff() {
    this.setState({
      loading: false
    });
  }

  /**
   * Get initial page
   *
   * @param {Object} props Passed props
   */
  _getInitialPage(props) {
    if (!props.parseUrl) return 1;
    return props.location.query.page ? props.location.query.page : 1;
  }

  /**
   * Get model
   */
  _getModel() {
    if (this.model) return this.model;

    this.model = new this.props.model();

    return this.model;
  }

  /**
   * Load items
   */
  async _loadItems(silent = false) {
    // Show loader
    if (!silent) {
      this._loadingOn();
    }

    try {
      const listParams = this._getListParams();
      const results = await this._getModel().fetch(listParams);

      this.setState(
        {
          loadedTimes: ++this.state.loadedTimes,
          items: results.items,
          pagination: results.pagination
        },
        () => {
          this._onLoaded();
        }
      );
    } catch (e) {}

    setTimeout(() => {
      this._loadingOff();
    }, 100);
  }

  _onLoaded() {
    this.props.onLoad(this.state);
  }

  async _refresh(force = true, silent = false) {
    if (force && this.props.parseUrl) {
      const pageUrl = `?${qs.stringify({
        ...this.props.location.query,
        page: this.state.pagination.page,
        filter: this.state.filter.filter(x => x.value && x.value !== ''),
        order: this.state.order
      })}`;

      window.history.pushState('', '', pageUrl);
      this._loadItems(silent);
      // history.push({
      //   search: qs.stringify({
      //     ...this.props.location.query,
      //     page: this.state.pagination.page,
      //     filter: this.state.filter,
      //     order: this.state.order
      //   })
      // })
    } else {
      window.scrollTo(0, 0);
      this._loadItems(silent);
    }
  }

  _getListParams() {
    const additionalParams = {};

    if (this.state.filter) {
      additionalParams.filter = this.state.filter;
      // additionalParams.filter = this.state.filter.filter(x => x.value && (x.value + "".length > 0));
    }

    if (this.state.order) {
      additionalParams.order = this.state.order;
    }

    return {
      offset: (this.state.pagination.page - 1) * this.state.pagination.limit,
      limit: this.state.pagination.limit,
      ...additionalParams,
      ...this.props.params
    };
  }

  /**
   * Called on page changed
   *
   * @param {Integer} pageNumber New page number
   */
  _pageChanged(pageNumber) {
    const pagination = { ...this.state.pagination };
    pagination.page = pageNumber;
    this.setState(
      {
        pagination
      },
      () => {
        this._refresh();
      }
    );
  }

  /**
   * Get rows
   */
  _getRows() {
    const columns = this._getColumns();

    if (this.state.loadedTimes > 0 && this.state.items.length == 0) {
      return (
        <tr className={`app-table-tr ${this.props.getRowClass()}`}>
          <td colSpan={columns.length}>No records</td>
        </tr>
      );
    }

    if (this.state.loadedTimes == 0 && this.state.loading) {
      return (
        <tr className={`app-table-tr ${this.props.getRowClass()}`}>
          <td colSpan={columns.length}>
            <LoadingOverlay />
          </td>
        </tr>
      );
    }

    const elements = [];

    this.state.items.forEach((item, index) => {
      const innerCells = [];
      columns.forEach(column => {
        innerCells.push(this._renderCell(column, item, index));
      });
      elements.push(
        <tr
          key={`tr${index}`}
          className={`app-table-tr ${this.props.getRowClass(item, index)}`}
        >
          {innerCells}
        </tr>
      );
    });
    return elements;
  }

  /**
   * Render single cell
   *
   * @param {Object} column Column which cell is rendered
   * @param {Object} item
   * @param {Integer} index
   */
  _renderCell(column, item, index) {
    if (column.type === 'actions') {
      return this._renderActionsCell(item, index);
    }

    // Extract
    let cellContent = _.get(item, column.name);

    const overridenContent = this.props.renderCell(column, item, index);

    if (overridenContent || overridenContent === null) {
      cellContent = overridenContent;
    }

    return (
      <td
        key={column.name + index}
        className={`app-table-td ${this.props.getCellClass(
          column,
          item,
          index
        )}`}
      >
        {cellContent}
      </td>
    );
  }

  /**
   * Render actions cell
   * @param column
   * @param item
   * @param index
   */
  _renderActionsCell(item, index) {
    const actions = this.props.getColumnActions(item, index);
    return (
      <td
        key={`td${index}`}
        className={`app-table-td ${this.props.getCellClass(null, item, index)}`}
        align="right"
      >
        {actions}
      </td>
    );
  }

  /**
   * Get table header
   */
  _getTableHeader() {
    return (
      <AppTableHead
        columns={this._getColumns()}
        order={this.state.order}
        onOrder={order => {
          this.setState(
            {
              order
            },
            () => {
              this._refresh();
            }
          );
        }}
      />
    );
  }

  /**
   * Get columns for table
   */
  _getColumns() {
    let columns = this._getModel().getTableColumns();
    const overridenColumns = this.props.getTableColumns();
    if (overridenColumns.length) columns = overridenColumns;
    return columns;
  }

  _filter(filter) {
    this.setState(
      {
        filter: this._joinFilters(this.state.filter, filter),
        pagination: {
          ...this.state.pagination,
          page: 1
        }
      },
      () => {
        this._refresh();
      }
    );
  }

  _getTableTitle() {
    const { icon, title, headerActions } = this.props;

    if (!title) {
      return null;
    }

    const iconElement = <AppIcon name={icon} />;

    return (
      <div>
        <div className="dark-blur" />
        <div className="box-header">
          <h2>
            {iconElement} {title}
          </h2>
          <div className="header-actions">
            {typeof headerActions === 'function'
              ? headerActions()
              : headerActions}
          </div>
        </div>
      </div>
    );
  }

  _getLoadingElement() {
    if (!this.state.loading || this.state.loadedTimes === 0) return null;

    return <LoadingOverlay />;
  }

  updateItems(callable) {
    const items = callable(this.state.items);
    this.setState({
      items
    });
  }

  /**
   * Render the component
   */
  render() {
    const tableHeader = this._getTableHeader();
    const rows = this._getRows();
    const title = this._getTableTitle();
    const loadingElement = this._getLoadingElement();

    return (
      <div className="element-with-blur">
        {title}
        {loadingElement}
        <div className="box-body">
          <AppTableFilter
            {...{ ...this.props, onInit: () => {} }}
            onFilter={filter => this._filter(filter)}
            padded
          />
          <table className="table">
            <tbody>
              {tableHeader}
              {rows}
            </tbody>
          </table>
          <div className="clear" />
          <AppTablePagination
            paginate={this.props.paginate}
            pagination={this.state.pagination}
            onPageChanged={page => this._pageChanged(page)}
          />
        </div>
      </div>
    );
  }
}

// Default props
AppTable.defaultProps = {
  onInit: () => {},
  onLoad: () => {},
  getTableColumns: () => [],
  getColumnActions: () => [],
  getCellClass: () => [],
  getRowClass: () => [],
  renderCell: () => false,
  params: {},
  parseUrl: true,
  paginate: true,
  filter: []
};

export default withRouter(AppTable);
