import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToastHelper from '../../util/ToastHelper';
import FormComponent from './FormComponent';
import AppButton from './AppButton';

export default class FormSubmitWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textMessage: ''
    };
    // Initial state
    this.formHandler = props.formHandler;

    this.formHandler.on('submitSuccess', data => {
      const customMessage = this.props.getModalMessage(data);
      this.setState({
        textMessage: customMessage || data.message || 'Action successful!'
        // redirect: true
      });

      if (props.onSuccess) {
        props.onSuccess(data);
      } else {
        if (customMessage === 'Not admin user') {
          ToastHelper.show(
            'We apologize but you do not have Admin credentials.'
          );
          return;
        }
        ToastHelper.success(this.state.textMessage);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 20);
      }
    });

    this.formHandler.on('submitError', data => {
      const customMessage = this.props.getModalMessage(data);
      this.setState(
        {
          textMessage:
            customMessage ||
            data.message ||
            data.errors[0].message ||
            'Oops, something is wrong, you can not finish this action.'
        },
        () => {
          ToastHelper.show(this.state.textMessage);
        }
      );
    });

    this.formHandler.on('inputChange', data => {
      this.setState(
        {
          textMessage: ''
        },
        () => {
          ToastHelper.close();
        }
      );
    });
  }

  _getButtonContent() {
    if (this.formHandler.state.isLoading || this.state.redirect) {
      return <CircularProgress className="circle-progress" size={30} />;
    }
    return <span>{this.props.buttonText}</span>;
  }

  goBack() {
    this.setState(
      {
        redirect: true
      },
      () => {
        return <Redirect to={this.props.to} />;
      }
    );
  }

  _getCancelFormButton() {
    if (this.props.noCancelButton) {
      return null;
    }

    return (
      <AppButton
        type="button"
        onClick={() => this.goBack()}
        style={{
          marginRight: 5,
          backgroundColor: '#b74343 !important'
        }}
        customstyle="custom-danger-btn"
      >
        Cancel
      </AppButton>
    );
  }

  render() {
    if (this.state.redirect && this.props.to) {
      return <Redirect to={this.props.to} />;
    }

    const buttonContent = this._getButtonContent();
    const cancelButton = this._getCancelFormButton();

    const { formHandler } = this;

    return (
      <div style={{ width: '100%' }}>
        <FormComponent formHandler={formHandler}>
          {this.props.children}

          {this.props.renderActionButtons ? (
            this.props.renderActionButtons(this.formHandler.state.isLoading)
          ) : (
            <div
              className={`submitButtonWrapper ${this.props.changeSubmitButtonWrapper}`}
            >
              <div
                className={`form-button-container ${this.props.buttonContainer}`}
              >
                {cancelButton}
                <AppButton
                  type="submit"
                  style={{ marginLeft: cancelButton ? 5 : 0 }}
                >
                  {buttonContent}
                </AppButton>
              </div>
            </div>
          )}

          {/* <div className='formRow' style={{ marginTop: 20, display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}> */}
        </FormComponent>
      </div>
    );
  }
}

FormSubmitWrapper.defaultProps = {
  buttonText: 'Save',
  getModalMessage: () => null,
  click: () => null,
  buttonContainer: '',
  changeSubmitButtonWrapper: ''
};
