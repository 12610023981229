// import { IModuleType } from '../../@launchpad/modules/ModulesService';
import LegalModule from '@launchpad/modules/legal/LegalModule';
import NotificationsModule from '@launchpad/modules/push/NotificationsModule';
// import ToolsModule from '@launchpad/modules/tools/ToolsModule';
import AccountsModule from './accounts/AccountsModule';
import CardsModule from './cards/CardsModule';
import ReportsModule from './reports/ReportsModule';
import TransactionsModule from './transactions/TransactionsModule';
import UsersModule from './users/UsersModule';

const modules: any = [
  new UsersModule(),
  new AccountsModule(),
  new CardsModule(),
  new TransactionsModule(),
  new ReportsModule(),
  new LegalModule(),
  new NotificationsModule()
  // new ToolsModule()
];

export default modules;
