import React, { Component } from 'react';
import Api from '@launchpad/logic/api/Api';
import { Link } from 'react-router-dom';
import Config from '../../../../../../@launchpad/config';
import { date } from '../../../../../../@launchpad/util/DateHelper';

export default class AccountUserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      owner: {}
    };
  }

  componentDidMount() {
    this._getAllUsers();
  }

  getUser() {
    const { userId, ownerId } = this.props;

    const { items } = this.state;

    const user = items.find(x => x.id === userId);
    const owner = items.find(x => x.id === ownerId);

    this.setState({
      user,
      owner
    });
  }

  async _getAllUsers() {
    const url = new URL(`${Config.url}admin/users`);
    url.search = new URLSearchParams({ page: '1', limit: '', value: '' });

    const getUrl = `admin/users${url.search}`;

    try {
      const result = await Api.call(getUrl, 'GET');

      this.setState(
        {
          items: result.data ? result.data : result
        },
        () => {
          this.getUser();
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { user, owner } = this.state;

    const kycStatus = owner.kycStatus || '';
    const newKycStatus = kycStatus.replace(/#|_/g, ' ');

    return (
      <div className="main">
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="box-header marginless">
            <h2>
              <i className="fa fa-user" />
              {` ${owner.firstName} ${owner.lastName}`}
            </h2>
          </div>

          <div className="customers-main">
            <div className="row">
              <div className="col">
                <div className="padded">
                  <div className="row text-white">
                    <div style={{ marginBottom: 20 }} className="col-lg-6">
                      <h4>Basic Info</h4>
                      <hr />
                      <dl>
                        <dt>First name:</dt>
                        <dd>{owner.firstName}</dd>
                        <dt>Last name:</dt>
                        <dd>{owner.lastName}</dd>
                        <dt>Username:</dt>
                        <dd>{owner.username ? owner.username : '-'}</dd>
                        <dt>E-mail address:</dt>
                        <dd>{owner.email}</dd>
                        <dt>Customer id:</dt>
                        <dd>{owner.id}</dd>
                        <dt>Birth date:</dt>
                        <dd>{date(owner.birthDate, 'D / MMMM / Y')}</dd>
                        <dt>Onboarding:</dt>
                        <dd>
                          {date(owner.registeredAt, 'D / MMMM / Y HH:mm')}
                        </dd>
                      </dl>
                    </div>
                    <div style={{ marginBottom: 20 }} className="col-lg-6">
                      <h4>KYC</h4>
                      <hr />
                      <dl>
                        <dt>Status:</dt>
                        <dd>{newKycStatus.toUpperCase()}</dd>
                      </dl>
                    </div>
                  </div>
                  <div className="row text-white">
                    <div style={{ marginBottom: 20 }} className="col-lg-6">
                      <h4>Address Info</h4>
                      <hr />
                      <dl>
                        <dt>Address Line One:</dt>
                        <dd>{owner.addressOne}</dd>
                        <dt>Address Line Two:</dt>
                        <dd>{owner.addressTwo}</dd>
                        <dt>City:</dt>
                        <dd>{owner.city}</dd>
                        <dt>Postcode:</dt>
                        <dd>{owner.postCode}</dd>
                        <dt>Country code:</dt>
                        <dd>{owner.countryCode}</dd>
                        <dt>Mobile:</dt>
                        <dd>{owner.mobile}</dd>
                      </dl>
                    </div>
                    {owner.id !== user.id ? (
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>Additional user</h4>
                        <hr />
                        <dl>
                          <dt>Additional user id:</dt>
                          <dd>
                            <Link
                              to={`/user-details/${user.id}`}
                              className="button"
                            >
                              {user.id || '-'}
                            </Link>
                          </dd>
                        </dl>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
