import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import BulkLoadDashboard from './bulk-load/BulkLoadDashboard';

export const NAME = 'transactions';

export default class TransactionsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'transaction.*';
  }

  getNavigation() {
    return [
      {
        label: 'Transactions',
        icon: 'exchange',
        path: null,
        permission: 'transactions.*',
        children: [
          {
            label: 'Transactions',
            icon: 'exchange',
            path: '/transaction',
            permission: 'transactions.*'
          },
          {
            label: 'Bulk Load',
            icon: 'plus',
            path: '/transactions/bulk-load',
            permission: 'transactions.*'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}transaction`}
        load={() => import('./TransactionsNavigation')}
      />,
      <AppRoute path="/transactions/bulk-load" component={BulkLoadDashboard} />
    ];
  }
}
