import axios, { Method } from 'axios';
import UID from 'uniquebrowserid';
import { OverrideService } from '@launchpad';
import Logger from '../../util/Logger';
// import store from '../store/AppStore';
import Config from '../../config';
import store from '../store/AppStore';

const uid = new UID().completeID();

let authStrategy: string | null = null;
let accessToken: string | null = null;
let refreshTokenPromise: Promise<any> | null = null;

export default class Api {
  static getBaseUrl() {
    if (Config.env === 'live') {
      return Config.url;
    }
    return Config.url;
  }

  static resolveToken() {
    return accessToken || store.getState().auth.token || null;
  }

  static setToken(token: string): void {
    accessToken = token;
  }

  static getProvidedDeviceId(): string {
    return uid;
  }

  static call(
    url: string,
    method: Method = 'POST',
    data = {} as any,
    onProgress: any = null,
    isRefresh = false
  ) {
    // Set auth strategy
    if (!authStrategy) {
      authStrategy = OverrideService.getConfig().authStrategy;
    }

    const authToken = this.resolveToken();
    const requestUrl = this.getBaseUrl() + url;
    const ajaxOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        'X-Device-Os': 'WEB',
        'Provided-Device-Id': uid
      },
      contentType: 'application/json',
      body: JSON.stringify(data)
    };

    if (authToken !== null) {
      ajaxOptions.headers.Authorization = `Bearer ${authToken}`;
    }
    Logger.log('api options', requestUrl, ajaxOptions);
    Logger.log('api request', requestUrl, data);

    return new Promise((resolve, reject) => {
      // const config =  {
      //   onUploadProgress: function(progressEvent: any) {
      //     var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      //   }
      // }

      axios({
        url: this.getBaseUrl() + url,
        method,
        // contentType: 'application/json',
        // withCredentials: true,
        headers: ajaxOptions.headers,
        withCredentials: authStrategy !== 'access-token',
        data: JSON.stringify(data),
        onUploadProgress(progressEvent: any) {
          if (onProgress) {
            onProgress(progressEvent);
          }
        }
      })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error.response) {
            Logger.log('data', error.response);

            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              const logoutProcedure = () => {
                localStorage.removeItem('AUTH_TOKEN');
                window.location.replace('/login');
              };

              // There is no refresh procedure if we are using access-token strategy
              if (authStrategy === 'access-token') {
                logoutProcedure();
                return;
              }

              // If this is a failed call after for token refresh - logout because refresh token is bad
              if (isRefresh) {
                logoutProcedure();
                return;
              }

              // Try to refresh the token
              this.refreshToken()
                .then(() => {
                  this.call(url, method, data, onProgress).then(d =>
                    resolve(d)
                  );
                  refreshTokenPromise = null;
                })
                .catch(() => {
                  logoutProcedure();
                  refreshTokenPromise = null;
                });

              return;
            }

            if (error.response.status === 200) {
              const { code } = error.response.data;

              if (code === 401 || code === 403) {
                localStorage.removeItem('AUTH_TOKEN');
                window.location.replace('/login');
                // store.dispatch(logoutTriggerAction())
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
          reject(error.response ? error.response.data : error);
        });
    });
  }

  static async guardedDownload(url) {
    const response: any = await this.call('tools/download/trigger', 'POST', {
      file: url
    });

    if (response.data.url) {
      window.location = response.data.url;
    }
  }

  /**
   * Download file
   */
  static downloadFile(url, method, params = {}) {
    const headers = {} as any;
    const { token } = store.getState().auth;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    axios({
      url: this.getBaseUrl() + url,
      method: method || 'POST',
      headers,
      responseType: 'blob',
      data: JSON.stringify(params)
    }).then(response => {
      const disposition = response.headers['content-disposition'];
      const filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  }

  static refreshToken() {
    // If already initialized promise - use it
    if (refreshTokenPromise) {
      return refreshTokenPromise;
    }

    // Refresh token is stored in promise variable, to avoid repeating it multiple times
    // For example, on page refresh, multiple API calls may get 401, and refreshToken() will be called for each
    // This way, we ensure that only one goes through
    refreshTokenPromise = new Promise((resolve, reject) => {
      Api.call('auth/session/refresh', 'GET', {}, false, true)
        .then((data: any) => {
          this.setToken(data.data.accessToken);
          resolve(true);
        })
        .catch(e => {
          reject(e);
        });
    });

    return refreshTokenPromise;
  }
}
