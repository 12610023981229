import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { currency } from '@launchpad/util/NumberHelper';
import { AppBadge, LoadingOverlay } from '@launchpad';
import { withRouter } from 'react-router';
import { LPOverridable } from '../../../../../../@launchpad/components';

class AccountInfo extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      loader: false
    };
  }

  _renderStatus(item) {
    switch (item.status.toLowerCase()) {
      case 'active':
      case 'ok':
        return <AppBadge text="Active" icon="check" />;
      case 'locked':
        return <AppBadge text="Locked" icon="lock" type="danger" />;
      case 'inactive':
        return <AppBadge text="Inactive" icon="ban" type="danger" />;
      case 'closed':
        return <AppBadge text="Closed" icon="ban" type="danger" />;
      case 'pending_activation':
        return (
          <AppBadge text="Pending activation" icon="clock-o" type="info" />
        );
      default:
        return null;
    }
  }

  redirectToUserDetials(userId) {
    this.setState(
      {
        loader: true
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              loader: false
            },
            () => {
              this.props.onRefresh();
              this.props.history.push('/');
            }
          );
          this.props.history.push(`/user-details/${userId}`);
        }, 3000);
      }
    );
  }

  render() {
    const { user, account } = this.props;

    const accountType = account.type.replaceAll('_', ' ').toUpperCase();

    return (
      <div className="main" key={user}>
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">
                  {account || account !== undefined
                    ? `${account.user.firstName} ${account.user.lastName}`
                    : 'Currently no account information.'}
                </span>
              </h2>
            </div>
          </div>

          <div className="customers-main">
            <div className="row">
              <div className="col">
                {!account || account === undefined || this.state.loader ? (
                  <LoadingOverlay />
                ) : (
                  <div className="padded">
                    <LPOverridable
                      name="user.details.basic.before"
                      user={user}
                    />
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-12">
                        <h4>Account Info</h4>
                        <hr />
                        <dl>
                          <dt>Account number:</dt>
                          <dd>{account.accountNumber || '-'}</dd>
                          <dt>Account id:</dt>
                          <dd>{account.id || '-'}</dd>
                          <dt>Account type:</dt>
                          <dd>{accountType || '-'}</dd>
                          <dt>Status:</dt>
                          <dd>{this._renderStatus(account) || '-'}</dd>
                          <dt>Balance:</dt>
                          <dd>
                            {currency(
                              account.availableBalance
                                ? account.availableBalance
                                : account.balance,
                              account.currency
                            )}
                          </dd>
                          <dt>Last top up:</dt>
                          <dd>
                            {currency(account.lastTopUp, account.currency)}
                          </dd>
                          <dt>Spent today:</dt>
                          <dd>
                            {currency(account.spentToday, account.currency)}
                          </dd>
                          <dt>IBAN:</dt>
                          <dd>{account.iban || '-'}</dd>
                          <dt>Sort code:</dt>
                          <dd>{account.sortCode || '-'}</dd>
                          <dt>Owner Id:</dt>
                          <dd>
                            <button
                              type="button"
                              className="button"
                              onClick={() =>
                                this.redirectToUserDetials(account.ownerId)
                              }
                            >
                              {account.ownerId || '-'}
                            </button>
                          </dd>
                          <dt>User id:</dt>
                          <dd>
                            <button
                              type="button"
                              className="button"
                              onClick={() =>
                                this.redirectToUserDetials(account.userUuid)
                              }
                            >
                              {account.userUuid || '-'}
                            </button>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountInfo);
