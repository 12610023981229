import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppTable } from '../../index';
import { LPOverridable } from '../../components';
import SubscribersWidget from './components/SubscribersWidget';
import SubscribersTrend from './components/SubscribersTrend';
import MessagesSentChart from './components/MessagesSentChart';
import SendNotificationModel from './models/SendNotificationModel';
import DevicesModel from '../users/user-devices/models/DevicesModel';
import AppBadge from '../../components/elements/icon/AppBadge';
import DateHelper from '../../util/DateHelper';

interface HTMLInputElement extends HTMLElement {
  getContext(arg0: string): any;
  /**
   * Returns the value of the data at the cursor's current position.
   */
  value: string;
}

declare global {
  interface Window {
    barChartConfig: any;
    myScatter: any;
  }
}

export default class PushDashboard extends Component<HTMLInputElement, any> {
  _tableComponent: any;

  constructor(props: Readonly<HTMLInputElement>) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-tachometer" aria-hidden="true" /> Push Dashboard
        </h1>
        <div className="main-inner" style={{ paddingBottom: 20 }}>
          <LPOverridable name="pages.push.widgets.top">
            <SubscribersWidget />
            <SubscribersTrend />
            <MessagesSentChart />
          </LPOverridable>
        </div>

        <LPOverridable name="pages.push.widgets.bottom">
          <div className="row">
            <div className="col-lg-6">
              <div className="activated-cards">
                <div className="dark-blur" />
                <div className="box-header">
                  <h2>
                    <i className="fa fa-users" /> Latest Subscribers
                  </h2>
                  <div className="box-header-action">
                    <Link to="/users/user-devices" className="button">
                      List all
                    </Link>
                  </div>
                </div>
                <div className="box-body">
                  <AppTable
                    title={null}
                    onInit={(tableComponent: any) =>
                      (this._tableComponent = tableComponent)
                    }
                    filter={null}
                    paginate={false}
                    params={{
                      limit: 10
                    }}
                    parseUrl={false}
                    order={[
                      {
                        field: 'id',
                        direction: 'desc'
                      }
                    ]}
                    model={DevicesModel}
                    getColumnActions={(member: { id: any }, index: any) => {
                      return (
                        <div>
                          <Link
                            to={`/users/user-devices/details/${member.id}`}
                            className="button-info"
                          >
                            Details
                          </Link>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="activated-cards">
                <div className="dark-blur" />
                <div className="box-header">
                  <h2>
                    <i className="fa fa-comments" /> Latest Pushes
                  </h2>
                  <div className="box-header-action">
                    <Link to="/push-notifications" className="button">
                      List all
                    </Link>
                  </div>
                </div>
                <div className="box-body">
                  <AppTable
                    title={null}
                    onInit={tableComponent =>
                      (this._tableComponent = tableComponent)
                    }
                    filter={null}
                    paginate={false}
                    params={{
                      limit: 10
                    }}
                    parseUrl={false}
                    order={[
                      {
                        field: 'id',
                        direction: 'desc'
                      }
                    ]}
                    model={SendNotificationModel}
                    renderCell={(column, item, index) => {
                      switch (column.name) {
                        case 'status':
                          return <AppBadge text={item.status} />;
                        case 'created':
                          return DateHelper.date(item.created, 'D / MMMM / Y');
                        default:
                          return undefined;
                      }
                    }}
                    getColumnActions={(sendNotification, index) => {
                      return (
                        <div>
                          <Link
                            to={`/push-notifications/details/${sendNotification.id}`}
                            className="button-info"
                          >
                            Details
                          </Link>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </LPOverridable>
      </div>
    );
  }
}
