// import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class AccountsModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/accounts';
  }

  /**
   * Get delete url
   */
  // getDeleteUrl(id) {
  //   return `admin/account/${id}`;
  // }

  // async delete(id) {
  //   const result = await Api.call(this.getDeleteUrl(id), 'DELETE');
  //   return result;
  // }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field'
      },
      {
        name: 'accountNumber',
        label: 'Account number',
        sortable: false,
        type: 'field'
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field'
      },
      {
        name: 'iban',
        label: 'IBAN',
        sortable: false,
        type: 'field'
      },
      {
        name: 'balance',
        label: 'Balance',
        sortable: false,
        type: 'field'
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field'
      },
      {
        name: 'userUuid',
        label: 'User',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }
}
